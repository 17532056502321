import { baseURL } from "../constants";
var axios = require("axios");

export const verifyOTP = (data) => {
  var givenData = JSON.stringify({
    otp: data.otp,
  });
  var config_verify = {
    method: "post",
    url: baseURL + "auth/partner/otp/verify",
    headers: {
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwNjczZmZlZjdkZTJlNjdkYzYyZWMyMSIsInR5cGUiOiIwMSIsImlhdCI6MTYxNzM3OTMyNiwiZXhwIjoxNjE5OTcxMzI2fQ.bZVlMepInz6QcJThlasGygCbcOsYyWEaN7rBQ_ZMhzQ",
      "Content-Type": "application/json",
    },
    data: givenData,
  };

  axios(config_verify)
    .then(function (response) {
      console.log(response);
      // OTPVerified(response);
    })
    .catch(function (error) {
      console.log(error);
      // OTPNotVerified(error.message);
    });
};

export const generateOTP = () => {
  var config = {
    method: "get",
    url: baseURL + "auth/partner/otp/generate",
    headers: {
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwNjczZmZlZjdkZTJlNjdkYzYyZWMyMSIsInR5cGUiOiIwMSIsImlhdCI6MTYxNzM3OTMyNiwiZXhwIjoxNjE5OTcxMzI2fQ.bZVlMepInz6QcJThlasGygCbcOsYyWEaN7rBQ_ZMhzQ",
    },
  };

  axios(config)
    .then(function (response) {
      // OTPGeneratedSuccess(response);
      console.log(response);
    })
    .catch(function (error) {
      // OTPGenerateFail(error.message);
      console.log(error);
    });
};
