export const baseURL = "https://run4me.herokuapp.com/";

export const UPDATE_USER_LOGIN = "UPDATE_USER_LOGIN";
export const CHECK_LOGIN_CREDENTIALS = "CHECK_LOGIN_CREDENTIALS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const UPDATE_USER_SIGNUP = "UPDATE_USER_SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const VERIFY_OTP = "VERIFY_OTP";
export const GENERATE_OTP = "GENERATE_OTP";

export const GET_PROFILE = "GET_PROFILE";

export const UPDATE_PRODUCTS_LIST = "UPDATE_PRODUCTS_LIST";
