import Login from "../../Login";
import { connect } from "react-redux";
import { userLogin, checkLoginCredentials } from "../actions/Login";

const mapStateToProps = (state) => ({
  errorMessage: state.Login.errorMessage,
  loggedIn: state.Login.loggedIn,
});

const mapDispatchToProps = (dispatch) => ({
  userLoginHandler: () => dispatch(userLogin()),
  checkLoginCredentials: (data) => dispatch(checkLoginCredentials(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
