import React from "react";

function ManageRefunds() {
  return (
    <div>
      <h2>ManageRefunds</h2>
    </div>
  );
}

export default ManageRefunds;
