import { combineReducers } from "redux";
import userSignupReducer from "./reducers/SignupReducer";
import userLoginReducer from "./reducers/LoginReducer";
import AuthReducers from "./reducers/AuthReducers";
import profileReducer from "./reducers/ProfileReducer";
import ecommerceReducer from "./reducers/EcommerceReducer";

export default combineReducers({
  Login: userLoginReducer,
  Signup: userSignupReducer,
  Authenticate: AuthReducers,
  Profile: profileReducer,
  Ecommerce: ecommerceReducer,
});
