import React from "react";

function ManageCoupons() {
  return (
    <div>
      <h2>Manage Coupons</h2>
    </div>
  );
}

export default ManageCoupons;
