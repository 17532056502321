/* eslint-disable */

import {
  UPDATE_USER_LOGIN,
  baseURL,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
} from "../constants";

var axios = require("axios");

import history from "../../history";

export const checkLoginCredentials = (userData) => {
  return (dispatch) => {
  var credentials = JSON.stringify(userData);
  var config = {
    method: "post",
    url: baseURL + "auth/partner/login",
    headers: {
      "Content-Type": "application/json",
    },
    data: credentials,
  };
  axios(config)
    .then(function (response) {
      dispatch(loginSuccess(response.data.token));
    })
    .catch(function (error) {
      dispatch(loginFail(error.message));
    });
  }
};

export const loginSuccess = (data) => {
  localStorage.setItem('user', data);
  history.push("/admin/dashboard");
  var config = {
    method: "post",
    url: baseURL + "profile/partner/profile",
    headers: {
      Authorization:
      "Bearer " + data,
    },
  };
  axios(config)
    .then(function (response) {
      console.log(response.data.details);
      localStorage.setItem('userData', JSON.stringify(response.data.details));
    })
  return {
    type: LOGIN_SUCCESS,
    payload: {
      ...data,
    },
  };
};

export const loginFail = (data) => {
  return {
    type: LOGIN_FAIL,
    payload: data,
  };
};

export const userLogin = () => {
  return {
    type: UPDATE_USER_LOGIN,
  };
};
