import Authentication from "../../Authentication";
import { connect } from "react-redux";
import { verifyOTP, generateOTP } from "../actions/Auth";

const mapStateToProps = (state) => ({
  data: state,
});

const mapDispatchToProps = (dispatch) => ({
  verifyOTPHandler: (data) => dispatch(verifyOTP(data)),
  generateOTPHandler: (data) => dispatch(generateOTP(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Authentication);
