/* eslint-disable */

import React from "react";
import Table from "components/Table/Table.js";
// @material-ui/icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import { MoreVert } from "@material-ui/icons";

function ManageOrders() {
  return (
    <div>
      <h2>ManageOrders</h2>
      <Table
        tableHeaderColor="warning"
        tableHead={["ID", "Name", "Salary", "Country", "QTY", "Options"]}
        tableData={[
          ["1", "Dakota Rice", "$36,738", "Niger", "35", <div> <Edit /> <Close /> <MoreVert /> </div>],
          ["2", "Minerva Hooper", "$23,789", "Curaçao", "15", <div> <Edit /> <Close /> <MoreVert /> </div>],
          ["3", "Sage Rodriguez", "$56,142", "Canada", "7", <div> <Edit /> <Close /> <MoreVert /> </div>],
          ["4", "Philip Chaney", "$38,735", "Korea, South", "10", <div> <Edit /> <Close /> <MoreVert /> </div>],
        ]}
      />
    </div>
  );
}

export default ManageOrders;
