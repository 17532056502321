/* eslint-disable */

import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";

import history from "./history";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  lower: {
    textAlign: "center",
  },
  error_box: {
    color: "red",
  },
}));

const Login = ({loggedIn, errorMessage, userId, checkLoginCredentials}) => {
  const classes = useStyles();
  
  const [inputs, setInputs] = useState({ email: "", password: "" });
  
  const [error, setError] = useState("");
  
  function validate(value){
    checkLoginCredentials(value);
  }
  
  // if localStorage has user token (means user logged in) then it will redirect to admin dashboard
  useEffect(() => {
    if(localStorage.getItem('user')){
      history.push("/admin/dashboard");
    }
  }, [])

  useEffect(() => {
    setError(errorMessage)
  }, [errorMessage])
  
  const handleChange = (e) => {
    setInputs({...inputs, [e.target.id]: e.target.value});
  }
  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <div className={classes.error_box}>
          {error}
        </div>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={inputs.email}
            onChange={handleChange}
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={inputs.password}
            onChange={handleChange}
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={e => { e.preventDefault(); validate(inputs) }}
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/auth" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      
      <Divider variant="middle" />

      <div className={classes.lower}>
        <p>or Connect By</p>
        
        <div className='social-login'>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Button 
                variant="contained"
                fullWidth
                color="primary"
                >
                Facebook
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
            <Button 
                fullWidth
                variant="contained"
                color="secondary"
                >
                Google
              </Button>
            </Grid>
          </Grid>
        </div>
        
      </div>
      
    </Container>
  );
}

export default Login;