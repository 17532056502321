/* eslint-disable */

import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  error_box: {
    color: "red",
  },
}));

const SignUp = (props) => {
  const classes = useStyles();
  
  const [inputs, setInputs] = useState({
    name : "",
    age : 20,
    gender :"Male",
    phoneNumber : "",
    email :"",
    location :"",
    profilePicture : "SAMPLE ( Hardcode for now )",
    storeDetails :"Details",
    ifscCode : "PYTM0123456",
    bankAccountNumber :"AL47 2121 1009 0000 0002 3569 8741",
    password : ""
});
  
  function validate(value){
    // if( check if all entries are valid )
      props.userSignupHandler(value)
    // else
      // setError("re enter password")
  }

  const [error, setError] = useState("");

  useEffect(() => {
    setError(props.errorMessage);
  }, [props.errorMessage])
  
  const handleChange = (e) => {
    setInputs({...inputs, [e.target.id]: e.target.value});
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <div className={classes.error_box}>
          {error}
        </div>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                autoComplete="name"
                name="name"
                variant="outlined"
                required
                fullWidth
                id="name"
                label="Full Name"
                value={inputs.name}
                onChange={handleChange}
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="location"
                label="Location"
                name="location"
                value={inputs.location}
                onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={inputs.email}
                onChange={handleChange}
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <PhoneInput
                inputStyle={{width: '100%', height: '56px'}}
                id="phoneNumber"
                label="Phone Number"
                name="phoneNumber"
                country={'in'}
                value={inputs.phoneNumber}
                onChange={ phoneNumber => setInputs({...inputs, phoneNumber: "+"+phoneNumber}) }
                autoComplete="phone"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={inputs.password}
                onChange={handleChange}
                autoComplete="current-password"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={e => { e.preventDefault(); validate(inputs) }}
            className={classes.submit}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

export default SignUp;