import UserProfile from "../../views/UserProfile/UserProfile";
import { connect } from "react-redux";
import { getProfile } from "../actions/Profile";

// const mapStateToProps = (state) => ({
//   data: state,
// });

const mapDispatchToProps = (dispatch) => ({
  getProfileHandler: (data) => dispatch(getProfile(data)),
});

export default connect(null, mapDispatchToProps)(UserProfile);
