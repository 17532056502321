// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
// import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.js";
import TableList from "views/TableList/TableList.js";
import Typography from "views/Typography/Typography.js";
import Icons from "views/Icons/Icons.js";
import Maps from "views/Maps/Maps.js";
import NotificationsPage from "views/Notifications/Notifications.js";
import PickDrop from "views/Services/PickDrop.js";
import RealEstate from "views/Services/RealEstate.js";
import TaxiService from "views/Services/TaxiService.js";

import UserProfile from "redux/containers/ProfileContainer";
import Ecommerce from "redux/containers/EcommerceContainer";

// sub-routing is working efficiently
// if you want to create sub-routes, define a array of routes named child in that route as done in services route
const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Home",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    name: "Services",
    icon: EmojiTransportationIcon,
    child: [
      {
        path: "/services/pickDrop",
        name: "Pick N Drop",
        icon: "services",
        component: PickDrop,
        layout: "/admin",
      },
      {
        path: "/services/taxiservice",
        name: "Taxi Service",
        icon: "services",
        component: TaxiService,
        layout: "/admin",
      },
      {
        path: "/services/realestate",
        name: "Real Estate",
        icon: "services",
        component: RealEstate,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/ecommerce",
    name: "E-commerce",
    icon: MonetizationOnIcon,
    component: Ecommerce,
    layout: "/admin",
  },
  {
    path: "/profile",
    name: "User Profile",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/admin",
  },
  {
    path: "/icons",
    name: "Chat Support",
    icon: BubbleChart,
    component: Icons,
    layout: "/admin",
  },
  {
    path: "/typography",
    name: "Announcements",
    icon: LibraryBooks,
    component: Typography,
    layout: "/admin",
  },
  {
    path: "/table",
    name: "More",
    icon: Unarchive,
    component: TableList,
    layout: "/admin",
  },
  {
    path: "/maps",
    name: "Maps",
    icon: LocationOn,
    component: Maps,
    layout: "/admin",
  },
];

export default dashboardRoutes;
