/* eslint-disable */
import React from 'react';
// react plugin for creating charts
import ChartistGraph from 'react-chartist';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
// @material-ui/icons
import MoreVertIcon from '@material-ui/icons/MoreVert'; 
import Store from '@material-ui/icons/Store';
import Warning from '@material-ui/icons/Warning';
import DateRange from '@material-ui/icons/DateRange';
import LocalOffer from '@material-ui/icons/LocalOffer';
import SearchIcon from '@material-ui/icons/Search';
import Update from '@material-ui/icons/Update';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import AccessTime from '@material-ui/icons/AccessTime';
import Accessibility from '@material-ui/icons/Accessibility';
import BugReport from '@material-ui/icons/BugReport';
import Code from '@material-ui/icons/Code';
import Cloud from '@material-ui/icons/Cloud';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import InputBase from '@material-ui/core/InputBase';
import Table from 'components/Table/Table.js';
import Tasks from 'components/Tasks/Tasks.js';
import CustomTabs from 'components/CustomTabs/CustomTabs.js';
import Danger from 'components/Typography/Danger.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import { bugs, website, server } from 'variables/general.js';

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from 'variables/charts.js';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
const localStyles = {
  search: {
    backgroundColor: "navajowhite",
    padding: "4px",
    margin: "10px 40px",
  },
};

const useStyles = makeStyles(styles);
const useLocalStyles = makeStyles(localStyles);

export default function PickDrop() {
  const classes = useStyles();
  const localClasses = useLocalStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>
                Customer Booking History
              </h4>
              <p className={classes.cardCategoryWhite}></p>
            </CardHeader>
            <div className={localClasses.search}>
              <span className={localClasses.searchIcon}>
                <SearchIcon />
              </span>
              <InputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={[
                  'Customer Name',
                  'Vehicle Registration Details',
                  'Contact No.',
                  'Email Id',
                  'Start Time',
                  'End Time',
                  'Options',
                ]}
                tableData={[
                  [
                    'IJK MNO',
                    'Dakota Rice',
                    '+91 9584816481',
                    'example@abc.com',
                    '31-30-2021 12:00pm',
                    '31-30-2021 2:00pm',
                    <MoreVertIcon />,
                  ],
                  [
                    'IJK MNO',
                    'Dakota Rice',
                    '+91 9584816481',
                    'example@abc.com',
                    '31-30-2021 12:00pm',
                    '31-30-2021 2:00pm',
                    <MoreVertIcon />,
                  ],
                  [
                    'IJK MNO',
                    'Dakota Rice',
                    '+91 9584816481',
                    'example@abc.com',
                    '31-30-2021 12:00pm',
                    '31-30-2021 2:00pm',
                    <MoreVertIcon />,
                  ],
                  [
                    'IJK MNO',
                    'Dakota Rice',
                    '+91 9584816481',
                    'example@abc.com',
                    '31-30-2021 12:00pm',
                    '31-30-2021 2:00pm',
                    <MoreVertIcon />,
                  ],
                  [
                    'IJK MNO',
                    'Dakota Rice',
                    '+91 9584816481',
                    'example@abc.com',
                    '31-30-2021 12:00pm',
                    '31-30-2021 2:00pm',
                    <MoreVertIcon />,
                  ],
                  [
                    'IJK MNO',
                    'Dakota Rice',
                    '+91 9584816481',
                    'example@abc.com',
                    '31-30-2021 12:00pm',
                    '31-30-2021 2:00pm',
                    <MoreVertIcon />,
                  ],
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Manage Booking</p>
              <h3 className={classes.cardTitle}>
                49/50 <small>GB</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Danger>
                  <Warning />
                </Danger>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  1250
                </a>
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Payment Gateway</p>
              <h3 className={classes.cardTitle}>$34,245</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 24 Hours
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={4} md={4}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Chat Support</p>
              <h3 className={classes.cardTitle}>75</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                Tracked from Github.com
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
