import { UPDATE_USER_LOGIN, LOGIN_FAIL, LOGIN_SUCCESS } from "../constants";

const initialState = { loggedIn: false, userId: "", errorMessage: "" };

const userLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      return { ...state, loggedIn: true };
    }
    case LOGIN_FAIL: {
      return { ...state, loggedIn: false, errorMessage: action.payload };
    }
    case UPDATE_USER_LOGIN:
      return { ...state, loggedIn: !state.loggedIn };
    default:
      return state;
  }
};

export default userLoginReducer;
