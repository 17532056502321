import { UPDATE_PRODUCTS_LIST } from "../constants";

const initialState = { productsList: [] };

const ecommerceReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PRODUCTS_LIST: {
      return { ...state, productsList: action.data };
    }
    default:
      return state;
  }
};

export default ecommerceReducer;
