import { SIGNUP_SUCCESS, SIGNUP_FAIL } from "../constants";

const initialState = {
  signupSuccessful: false,
  errorMessage: "",
  userId: "",
};

const userSignupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_SUCCESS: {
      return { ...state, signupSuccessful: true };
    }
    case SIGNUP_FAIL: {
      return {
        ...state,
        signupSuccessful: false,
        errorMessage: action.payload,
      };
    }
    default:
      return state;
  }
};

export default userSignupReducer;
