/* eslint-disable */

import React, { useEffect } from 'react';
// react plugin for creating charts
import ChartistGraph from 'react-chartist';
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from '@material-ui/icons/BugReport';
import Code from '@material-ui/icons/Code';
import Cloud from '@material-ui/icons/Cloud';
// core components
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Table from 'components/Table/Table.js';
import Tasks from 'components/Tasks/Tasks.js';
import CustomTabs from 'components/CustomTabs/CustomTabs.js';
import Danger from 'components/Typography/Danger.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardIcon from 'components/Card/CardIcon.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';

import { bugs, website, server } from 'variables/general.js';

import {
  dailySalesChart,
  emailsSubscriptionChart,
  completedTasksChart,
} from 'variables/charts.js';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle.js';
import ManageOrders from './ManageOrders';
import ManageProducts from './ManageProducts';
import ManageCoupons from './ManageCoupons';
import ManageRefunds from './ManageRefunds';

const useStyles = makeStyles(styles);

export default function Ecommerce({ getProductsHandler, productsList, addNewProductHandler }) {
  
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
            title="Manage:"
            headerColor="primary"
            tabs={[
              {
                tabName: 'Products',
                tabIcon: Code,
                tabContent: (
                  <div>
                  <ManageProducts getProductsHandler={getProductsHandler} productsList={productsList} addNewProductHandler={addNewProductHandler} />
                    {/* <Tasks
                      checkedIndexes={[0]}
                      tasksIndexes={[0, 1]}
                      tasks={website}
                    /> */}
                  </div>
                ),
              },
              {
                tabName: 'Orders',
                tabIcon: BugReport,
                tabContent: (
                  <div>
                    <ManageOrders />
                    <Tasks
                      checkedIndexes={[0, 3]}
                      tasksIndexes={[0, 1, 2, 3]}
                      tasks={bugs}
                    />
                  </div>
                ),
              },
              {
                tabName: 'Discounts',
                tabIcon: Cloud,
                tabContent: (
                  <div>
                  <ManageCoupons />
                    <Tasks
                      checkedIndexes={[1]}
                      tasksIndexes={[0, 1, 2]}
                      tasks={server}
                    />
                  </div>
                ),
              },
              {
                tabName: 'Refunds',
                tabIcon: Cloud,
                tabContent: (
                  <div>
                  <ManageRefunds />
                    <Tasks
                      checkedIndexes={[1]}
                      tasksIndexes={[0, 1, 2]}
                      tasks={server}
                    />
                  </div>
                ),
              },
            ]}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
