import { baseURL } from "../constants";

var axios = require("axios");

// import history from "../../history";

export const getProfile = (userToken) => {
  return () => {
    var token = JSON.stringify(userToken);
    console.log(token);
    var config = {
      method: "post",
      url: baseURL + "profile/partner/profile",
      headers: {
        Authorization:
          "Bearer " +
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwN2E5MDdmYWM1YmFhMDAxNTgyMmQ5ZSIsInR5cGUiOiIwMSIsImlhdCI6MTYxOTQxNDIyOSwiZXhwIjoxNjIyMDA2MjI5fQ.mb41KBy5Px6RcaarNMPBcLvo4uCAVfytQ6SDuVIEiXw",
      },
    };
    axios(config)
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error.message);
      });
  };
};

// export const loginSuccess = (data) => {
//   localStorage.setItem("user", data);
//   history.push("/admin/dashboard");
//   return {
//     type: LOGIN_SUCCESS,
//     payload: {
//       ...data,
//     },
//   };
// };

// export const loginFail = (data) => {
//   return {
//     type: LOGIN_FAIL,
//     payload: data,
//   };
// };

// export const userLogin = () => {
//   return {
//     type: UPDATE_USER_LOGIN,
//   };
// };
