/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Container, CssBaseline, Grid } from "@material-ui/core";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    "aria-controls": `action-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  root: {
    maxWidth: 500,
  },
}));

const Authentication = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const [inputs, setInputs] = React.useState({
    email: "",
    phone: "",
    otp: "",
  });

  function validate(value) {
    // if( check if inputs are correct )
    props.verifyOTPHandler(value);
    props.history.push("/reset");
    // else
    // setError("Incorrect OTP")
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  
  const handleInputsChange = (e) => {
    setInputs({...inputs, [e.target.id]: e.target.value});
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Container className={classes.paper}>
        <Typography component="h1" variant="h5">
          Authentication
        </Typography>
      </Container>
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="action tabs example"
          >
            <Tab label="By Email" {...a11yProps(0)} />
            <Tab label="By Phone" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={inputs.email}
                onChange={handleInputsChange}
                autoComplete="email"
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  props.generateOTPHandler(inputs);
                }}
                className={classes.submit}
              >
                Generate OTP
              </Button>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="otp"
                label="OTP"
                type="otp"
                id="otp"
                value={inputs.otp}
                onChange={handleInputsChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  validate(inputs);
                }}
                className={classes.submit}
              >
                Sign In
              </Button>
            </form>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <form className={classes.form} noValidate>
            <Grid item xs={12}>
              <PhoneInput
                inputStyle={{width: '100%', height: '56px'}}
                id="phone"
                label="Phone Number"
                name="phone"
                country={'in'}
                value={inputs.phone}
                onChange={handleInputsChange}
                autoComplete="phone"
              />
            </Grid>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  props.generateOTPHandler(inputs);
                }}
                className={classes.submit}
              >
                Generate OTP
              </Button>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="otp"
                label="OTP"
                type="otp"
                id="otp"
                value={inputs.otp}
                onChange={handleInputsChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  validate(inputs);
                }}
                className={classes.submit}
              >
                Sign In
              </Button>
            </form>
          </TabPanel>
        </SwipeableViews>
      </div>
    </Container>
  );
};

export default Authentication;
