import Ecommerce from "../../views/Ecommerce/Ecommerce";
import { connect } from "react-redux";
import { getProducts, addNewProduct } from "../actions/Ecommerce";

const mapStateToProps = (state) => ({
  productsList: state.Ecommerce.productsList,
});

const mapDispatchToProps = (dispatch) => ({
  getProductsHandler: () => dispatch(getProducts()),
  addNewProductHandler: (data) => dispatch(addNewProduct(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Ecommerce);
