import Signup from "../../Signup";
import { connect } from "react-redux";
import { userSignup } from "../actions/Signup";

// this will attach state and actions to component
const mapStateToProps = (state) => ({
  errorMessage: state.Signup.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  userSignupHandler: (data) => dispatch(userSignup(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
