import { baseURL, SIGNUP_SUCCESS, SIGNUP_FAIL } from "../constants";

var axios = require("axios");

import history from "../../history";

export const userSignup = (userData) => {
  return (dispatch) => {
    var givenData = JSON.stringify(userData);
    var config = {
      method: "post",
      url: baseURL + "auth/partner/signup",
      headers: {
        "Content-Type": "application/json",
      },
      data: givenData,
    };
    axios(config)
      .then(function (response) {
        dispatch(signupSuccess(response.data.token));
      })
      .catch(function (error) {
        dispatch(signupFail(error.response.data.message));
      });
  };
};

export const signupSuccess = (data) => {
  localStorage.setItem("user", data);
  history.push("/admin/dashboard");
  return {
    type: SIGNUP_SUCCESS,
    payload: data,
  };
};

export const signupFail = (data) => {
  return {
    type: SIGNUP_FAIL,
    payload: data,
  };
};
