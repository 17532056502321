import { VERIFY_OTP, GENERATE_OTP } from "../constants";

const initialState = {};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_OTP: {
      return state;
    }
    case GENERATE_OTP: {
      return state;
    }
    default:
      return state;
  }
};

export default AuthReducer;
