/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function ManageProducts({
  getProductsHandler,
  productsList,
  addNewProductHandler,
}) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProductsHandler();
  }, []);

  useEffect(() => {
    console.log(productsList);
    setProducts(productsList);
    console.log(products);
  }, [productsList]);

  const [newProduct, setNewProduct] = useState({
    productName: "",
    productQuantity: "",
    originalPrice: "",
    productPrice: "",
  });

  const handleChange = (e) => {
    setNewProduct({ ...newProduct, [e.target.id]: e.target.value });
  };

  const handleNewProductSubmit = (e) => {
    e.preventDefault();
    addNewProductHandler(newProduct);
    handleClose();
  };

  return (
    <div>
      <h2>ManageProducts</h2>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add Product +
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">ADD PRODUCT</DialogTitle>
        <DialogContent>
          <DialogContentText>Enter Product Details</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="productName"
            label="Product Name"
            type="text"
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            id="productQuantity"
            label="Quantity"
            type="number"
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            id="originalPrice"
            label="Original Price"
            type="number"
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            id="productPrice"
            label="Product Price"
            type="number"
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleNewProductSubmit} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell align="right">Quantity</TableCell>
                <TableCell align="right">Original Price</TableCell>
                <TableCell align="right">Product Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productsList.map((row) => (
                <TableRow key={row._id}>
                  <TableCell component="th" scope="row">
                    {row.productName}
                  </TableCell>
                  <TableCell align="right">{row.productQuantity}</TableCell>
                  <TableCell align="right">{row.originalPrice}</TableCell>
                  <TableCell align="right">{row.productPrice}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default ManageProducts;
