import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/material-dashboard-react.css?v=1.9.0";

// core components
import Admin from "layouts/Admin.js";

import LoginContainer from "redux/containers/LoginContainer";
import SignupContainer from "redux/containers/SignupContainer";
import Reset from "Reset";
import AuthenticationContainer from "redux/containers/AuthContainer";

import hist from "./history";

function App() {
  return (
    <Router history={hist}>
      <Switch>
        <Route path="/login" component={LoginContainer} />
        <Route path="/signup" component={SignupContainer} />
        <Route path="/reset" component={Reset} />
        <Route path="/auth" component={AuthenticationContainer} />
        <Route path="/admin" component={Admin} />
        <Redirect from="/" to="/login" />
      </Switch>
    </Router>
  );
}

export default App;
