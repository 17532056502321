var axios = require("axios");

import { UPDATE_PRODUCTS_LIST, baseURL } from "../constants";

export const getProducts = () => {
  return (dispatch) => {
    const userToken = localStorage.getItem("user");
    var config = {
      method: "get",
      url: baseURL + "e-commerce/all",
      headers: {
        Authorization: "Bearer " + userToken,
      },
    };
    axios(config)
      .then(function (response) {
        dispatch(updateProductsList(response.data.items));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const addNewProduct = (productData) => {
  return (dispatch) => {
    const userToken = localStorage.getItem("user");
    var data = JSON.stringify(productData);
    var config = {
      method: "post",
      url: baseURL + "e-commerce/partner/add",
      headers: {
        Authorization: "Bearer " + userToken,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        dispatch(getProducts());
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const updateProductsList = (data) => {
  return {
    type: UPDATE_PRODUCTS_LIST,
    data: data,
  };
};
